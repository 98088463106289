
























import { Component, Vue } from 'vue-property-decorator';

import NavMenu from '@/components/NavSidebar/NavSidebar.vue';
import Header from '@/pages/War/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import { configureColors } from '@/share/Util/Color/ConfigureColors';
import { DARK_MODE_THEME, THEME } from '@/share/Util/Color/PlatformColors';

@Component({
  components: {
    NavMenu,
    Header,
    Footer
  }
})
export default class DefaultLayout extends Vue {
  created() {
    this.setTheme();
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get currentRoute() {
    return this.$route.path;
  }

  setTheme() {
    const routeHasConquistae = this.currentRoute && this.currentRoute.includes('conquistae');
    if (routeHasConquistae) {
      this.$store.commit('setIsDarkMode', true);
      configureColors(THEME[DARK_MODE_THEME]);
    }

  }

  movieClick(): void {
    this.$store.commit('setIsMovie', !this.$store.getters.isMovie);
  }
}
